<template>
  <div>
    <div class="padding-20">
      <div class="flex-row  align-items-center">
        <i class="el-icon-arrow-left font-size-24 bold margin-right-10 link" @click="$router.go(-1)"></i>
        <span class="font-size-20">{{modelInfo.customModelName}} — 情景模拟</span>
      </div>
      <div class="column margin-top-20" style="margin-bottom:0;">
        <div class="flex-row align-items-center justify-between">
          <div class="flex-row  align-items-center">
            <span>预测目标：</span>
            <div v-if="targetInfo">
              <el-popover placement="right-start" width="400" trigger="hover">
                <div class="font-size-12 line-height-large">
                  <div  v-if="targetInfo.desc">
                    <div><span class="color-sub">数据名称：</span>{{ targetInfo.desc.name }}</div>
                    <div><span class="color-sub">数据单位：</span>{{ targetInfo.desc.unit }}</div>
                    <div><span class="color-sub">市场名称：</span>{{ targetInfo.desc.market }}</div>
                    <div><span class="color-sub">规格牌号：</span>{{ targetInfo.desc.dataModel }}</div>
                    <div><span class="color-sub">更新频率：</span>{{ targetInfo.desc.frequency }}</div>
                    <div><span class="color-sub">统计日期：</span>{{ targetInfo.desc.time }}</div>
                    <div><span class="color-sub">样本说明：</span>{{ targetInfo.desc.explain }}</div>
                    <div><span class="color-sub">方法论：</span>{{ targetInfo.desc.func }}</div>
                    <div><span class="color-sub">数据来源：</span>{{ targetInfo.desc.dataSource }}</div>
                  </div>
                </div>
                <span slot="reference">
                  <span class="link">{{targetInfo.customDivisorName}}</span>
                </span>
              </el-popover>
            </div>
          </div>
          <div><el-button size="mini" :disabled="!divisorsData.length" type="primary" :loading="getRunIdLoading" @click="getRunId">运算</el-button></div>
        </div>
        <div id="dragMain" class="flex-column margin-top-10">
          <div id="topBox" ref="topBox">

              <div>
                <el-table
                  ref="table"
                  :height="divisorsListHeight"
                  size="medium"
                  :data="divisorsData"
                  style="width: 100%;">
                  <div slot="empty"><el-empty :image-size="80" description="暂无数据"></el-empty></div>
                  <el-table-column
                    min-width="250"
                    fixed="left"
                    prop="divisorName"
                    label="因子"
                  >
                    <template slot="header">
                      <span>因子</span>
                      <span class="color-sub font-size-12" style="font-weight: normal;"> （数量：{{divisorsData.length}}）</span>
                    </template>
                    <template slot-scope="props">
                        <div>
                          <span>{{props.row.divisorName}}</span>
                          <span class="color-sub font-size-12 text-nowrap">{{props.row.unit?`（${props.row.unit}）`:''}}</span>
                        </div>
                    </template>
                  </el-table-column>
                  <template v-if="divisorsData.length">
                    <el-table-column v-for="(item,index) in divisorsData[0].valuesTimesShow" :key="index" min-width="105" :label="q.modelFreq=='week'?item:$dateFormat(item,'yyyy-MM')" align="center">
                      <template  slot-scope="props">
                        <div style="min-width:95px;width:100%;" :class="{'required':q.modelFreq=='week'&&!$numFormat(props.row.divisorValues[index].value)&&props.row.divisorValues.slice(index,props.row.divisorValues.length).some(v=>{return $numFormat(v.value)})}">
                          <el-input class="border" :disabled="props.row.divisorValues[index].valueType==1" :placeholder="q.modelFreq=='week'&&!$numFormat(props.row.divisorValues[index].value)&&props.row.divisorValues.slice(index,props.row.divisorValues.length).some(v=>{return $numFormat(v.value)})?'必填':'输入数值'" clearable
                            v-model.trim="props.row.divisorValues[index].value"
                            size="mini"
                            maxlength="10"
                          ></el-input>
                        </div>
                        
                      </template>
                    </el-table-column>
                    <el-table-column width="50" label="操作" align="center" fixed="right">
                      <template  slot-scope="props">
                        <el-button type="text" size="mini" @click="clearDivisorsValues(props.row)">清空</el-button>
                      </template>
                    </el-table-column>
                  </template>
                </el-table>
              </div>

          </div>

          <div id="resizeBar"></div>

          <div id="downBox" class="flex-column">
            <div class="flex-auto flex-column" style="padding:20px">
              <div class="flex-row align-items-center justify-between text-nowrap">
                <span class="color-sub font-size-12">预测周期：<template>{{((result||{}).startTime||(modelForecast||{}).startTime) | dateFormat(this.q.modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM')}}至{{((result||{}).endTime||(modelForecast||{}).endTime) | dateFormat(this.q.modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM')}}</template></span>
              </div>
              <Chart :option="forecastChart" emptyText="暂无运算数据" height="100px" class="flex-auto"></Chart>
            </div>
          </div>
        </div>
        <div class="flex-row justify-center border-top padding-top-10">
          <el-button size="mini" type="primary" :disabled="!result" :loading="saveLoading"  @click="saveModel">保存</el-button>
        </div>
      </div>
        <el-dialog
          custom-class="custom-dialog"
          :append-to-body="true"
          top="30vh"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :show-close="false"
          :visible.sync="progress.visible"
          width="40%"
          >
          <div slot="title">
            <div class="font-size-20">模型正在计算，预计需要{{(Math.round(progress.totalTime/60)||1)}}分钟，请稍候...</div>
            <div class="color-sub margin-top-10">温馨提示：刷新或关闭页面会中止运算，并将页面初始化。</div>
          </div>
          <Progress :totalSeconds="progress.totalTime" :finished="progress.finished" v-if="progress.visible" @cancel="cancelOperation"></Progress>
        </el-dialog>

    </div>
  </div>
</template>

<script>
import crypto from "@/common/crypto";
import Chart from "@/components/Chart";
import Progress from "@/components/Progress";
import progressTotalTime from "@/common/progressTotalTime";//进度条时间计算方法
import { dragTwoRowDiv } from "@/common/dragResize";
export default {
  name: 'CustomScene',
  components:{
    Chart,
    Progress,
  },
  props:{
   
  },
  data (){
    return{
      targetInfo:null,//目标价信息
      modelInfo:{},//顶部模型信息
      divisorsListHeight:0,//已选因子列表高度
      runTimer:null,//运算轮巡定时器
      getRunIdLoading:false,
      dagRunId:'',
      publishId:'',
      successPublishId:'',

      divisorsData:[],
      modelForecast:{},
      result:null,
      saveLoading:false,
      progress:{
        visible:false,
        finished:true,
        totalTime:60,
      },
      
    }
  },
  created(){
    this.getData()
  },
  
  mounted(){
    dragTwoRowDiv("dragMain", "topBox", "resizeBar", "downBox");
    this.$nextTick(()=>{
      this.divisorsListHeight=this.$refs.topBox.clientHeight-10
    })
    this.sizeLinstener();//右侧可拖动容器尺寸变化监听
    
  },
  destroyed(){
   this.killDag()
  },
  
  methods:{
    getData(){
      if(this.q.modelId){
        this.getTargetInfo()
        this.getModelInfo()
        this.getModelForecast()
        this.getdivisors(this.q.publishId)
      }
    },
    //获取目标价信息
    getTargetInfo(){
      let request=this.$instance.get('/befarCustomModel/getCustomTargetInfo',{
          params: {
           ...this.$store.state.basicParams,
           modelId:this.q.modelId,
          },
      })
      request.then(res=>{
        if(res.data.code==0){
          this.targetInfo=res.data.info
        }else{
          this.targetInfo=null
        }
      })
      request.catch(()=>{
        this.targetInfo=null
      })
      return request
    },
    //获取模型信息
    getModelInfo(){
      let request=this.$instance.get('/befarCustomModel/getCustomModelInfo',{
          params: {
            ...this.$store.state.basicParams,
            modelId:this.q.modelId,
            modelFreq:this.q.modelFreq
          },
      })
      request.then(res=>{
        if(res.data.code==0){
          this.modelInfo=res.data.info
        }
        else{
          this.modelInfo={}
        }
      })
      request.catch(()=> {
        this.modelInfo={}
      }); 
      return request
    },
    //获取模型预测
    getModelForecast(){
      this.$instance.get('/befarCustomModel/getTargetPredictValue',{
          params: {
            ...this.$store.state.basicParams,
            customModelId:this.q.modelId,
            modelFreq:this.q.modelFreq
          },
      })
      .then(res=>{
        if(res.data.code==0){
          this.modelForecast=res.data.info
        }
        else{
          this.modelForecast={}
        }
      })
      .catch(()=>{
        this.modelForecast={}
      })
    },
   //页面尺寸变化监听
    sizeLinstener(){
      this.$erd.listenTo({
          strategy: "scroll", //<- For ultra performance.
          callOnAdd: false,
          debug: false
      },this.$refs.topBox, (dom)=>{
          this.divisorsListHeight=dom.clientHeight-10
      });
    },
    getdivisors(publishId){
      this.$instance.get('/befarCustomModel/getModelDivsiorValue',{
        params:{
          modelFreq:this.q.modelFreq,
          modelId:this.q.modelId,
          publishId:publishId,
        }
      })
      .then(res=>{
        if(res.data.code==0){
          this.divisorsData=(res.data.info||{}).customDivisors||[]
          this.$nextTick(()=>{
            this.$refs.table.doLayout()
          })
        }
      })
      .catch(()=>{

      })
    },
    //运算
    getRunId(){
      let allow=true
      if(this.q.modelFreq=='week'){
        for(let item of this.divisorsData){
          if (item.divisorValues.findIndex(v=>!this.$numFormat(v.value))>=0&&item.divisorValues.slice(item.divisorValues.findIndex(v=>!this.$numFormat(v.value)),item.divisorValues.length).some(v=>{return this.$numFormat(v.value)})){
            allow=false
            break;
          }
        }
      }
      if(!allow){
        this.$message({message:'请输入因子必填值',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        return false
      }
      this.getRunIdLoading=true
      let requestData={
        modelFreq:this.q.modelFreq,
        modelId:this.q.modelId,
        divisors:this.divisorsData.map(v=>{return{
          "divisorId": v.divisorId,
          "divisorValues": v.divisorValues.map(vv=>{return vv.value}),
          "divisorValueTypes": v.divisorValues.map(vv=>{return vv.valueType}),
          "months":  v.valuesTimes,
        }})
      }
      this.$instance.post('/befarCustomModel/calScene',requestData)
      .then(res=>{
        this.getRunIdLoading=false
        if(res.data.code==0){
          this.progress.totalTime=progressTotalTime(this.q.modelFreq=='week'?null:30000,this.divisorsData.length+1)
          this.progress.finished=false
          this.progress.visible=true
          this.dagRunId=res.data.info.dagRunId
          this.publishId=res.data.info.publishId
          this.runModel()
        }
      })
      .catch(()=>{
        this.getRunIdLoading=false
      })
    },
    runModel(){
      this.$instance.get('/befarCustomModel/getTargetCustomValue',{
        params: {
          ...this.$store.state.basicParams,
          dagRunId:this.dagRunId,
          modelFreq:this.q.modelFreq,
          modelId:this.q.modelId,
          publishId:this.publishId,
          type:'cond'
        },
      })
      .then(res=>{
        if(res.data.code==0){
          this.result=res.data.info
          this.getdivisors(this.publishId)
          this.successPublishId=this.publishId
          this.progress.finished=true
          setTimeout(()=>{this.progress.visible=false},1000)

        }else if(res.data.code==1){
          this.$message.closeAll()
          this.runTimer=setTimeout(()=>{this.runModel()},3000)
        }else{
          this.progress.finished=true
          this.progress.visible=false
        }
      })
      .catch(()=>{
        this.progress.finished=true
          this.progress.visible=false
      })
    },
    //中止运算
    killDag(){
      !this.progress.finished&&this.dagRunId&&this.cancelOperation()
      !this.progress.finished&&clearTimeout(this.runTimer)
    },
    cancelOperation(){
      this.$instance.get('/befarCustomModel/killDag',{
          params: {
            ...this.$store.state.basicParams,
            dagRunId:this.dagRunId,
            dagId:this.q.modelFreq=='week'?'befar_pf_custom_condition_week ':'befar_pf_custom_condition_month'
          },
      })
      .then(res=>{
          if(res.data.code==0){
            this.progress.finished=true
            this.progress.visible=false;
            clearTimeout(this.runTimer)
          }
      })
      .catch(()=> {}); 
    },
    saveModel(){
      this.saveLoading=true
      this.$instance.get('/befarCustomModel/modifyModelName',{
          params: {
           ...this.$store.state.basicParams,
           modelId:this.q.modelId,
           modelName:this.modelInfo.customModelName,
           publishId:this.successPublishId,
          },
      })
      .then(res=>{
        this.saveLoading=false
        if(res.data.code==0){
          this.$message({message:'保存成功，请前往“结果查看”中查看',type:'success',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        }
      })
      .catch(()=>{
        this.saveLoading=false
      })
    },
    //清空模型因子值
    clearDivisorsValues(row){
      if(row){
        row.divisorValues.forEach(v=>{
            if (v.valueType!=1){
              v.value=''
            }
        })
      }else{
        this.divisorsData.forEach(v=>{
          v.divisorValues.forEach(vv=>{
            if (vv.valueType!=1){
              vv.value=''
            }
          })
        })
      }
      
      
    },

  },
  computed:{
    q(){
      try {
        let q=JSON.parse(crypto.decrypt(this.$route.query.q))
        return q
      } catch (error) {
        return {}
      }
    },
    divisorsValueAllEmpty(){
      return this.divisorsData.every(v=>{return v.divisorValues.every(vv=>{return vv.valueType==1?true:!vv.value})})
    },
    //预测结果图表
    forecastChart(){
      let chartData=this.result||this.modelForecast||{}
      if(chartData.items){
        let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
        opt.yAxis.name=chartData.items[0].unit
        opt.xAxis.boundaryGap = false
        opt.xAxis.data=chartData.dataList.map(v=>{return v.dataCycle})
        // opt.dataZoom.startValue=opt.xAxis.data.length-15
        opt.legend.data=chartData.items.map(v=>{
          return {
            name:v.name,
            unit:v.unit,
            itemStyle:{
                opacity:0
            }
          }
        })
        opt.series=chartData.items.map(v=>{
          return {
            name: v.name,
            id:v.id,
            type: 'line', 
            connectNulls:true,
            lineStyle:{
              type:v.id=='his'?"solid":"dashed",
            },
            data: chartData.dataList.map(vv=>{return vv[v.id]}),
          }
        }) 
        opt.tooltip.formatter = (params)=>{
          let str=`<div>${params[0].axisValue}</div>`
          params.forEach((val)=>{
            if(this.$numFormat(val.data)){
              let item=chartData.items[val.seriesIndex]
              if(item.id!='his'){
                if(!this.$numFormat(chartData.dataList[val.dataIndex]['his'])||!params.some(v=>v.seriesId=='his')){
                  str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
                }
              }else{
                str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
              }
            }
          })
          return str
        }
        return opt
      }else{
        return null
      }
    },
  },
  watch: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss" scoped>

#dragMain {
  width: 100%;
  height: calc(100vh - 260px);
  min-height: 600px;
  overflow: hidden;
  position: relative;
}
#topBox {
  height: calc(60% - 10px);
  overflow: hidden;
  box-sizing: border-box;
}
#resizeBar {
  height: 10px;
  flex:0 0 10px;
  @include themeify{
    background:themed('background');
  };
  cursor: s-resize;
  position: relative;
}

#resizeBar::before,#resizeBar>.tips{
  position: absolute;
  left: 50%;
  top: 0;
  content: "";
  width: 80px;
  margin-left:-40px;
  height: 10px;
  @include themeify{
    background:themed('lineColor');
  };
}
#resizeBar::after{
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  width: 40px;
  margin-left:-20px;
  height: 2px;
  margin-top:-2px;
  @include themeify{
    border-top: 1px solid themed('columnBackground');
     border-bottom: 1px solid themed('columnBackground');
  };
}
#downBox {
  height: 40%;
  box-sizing: border-box;
  overflow: hidden;
}

</style>
<style lang="scss">
.required{
  .el-input .el-input__inner{
    border-color: red!important;
  }
}
</style>
